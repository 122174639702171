import React from "react"
import { SectionTitle } from "@hemalr/react-blocks"
import styled from "styled-components"
import { primaryColor } from "../components/site-config"
import Subtitle from "../components/subtitle"
import Layout from "../components/layout"
import SEO from "../components/seo"

const StyledDiv = styled.div`
  padding: 0 2rem;
  font-size: 1rem;
  height: 700px;
`

const CalendlyContainer = styled.div`
  height: 800px;
  margin-top: 1rem;
`

const BookPage = () => {
  return (
    <Layout>
      <SEO title="Book an appointment with Everything Accounting" />
      <StyledDiv>
        <SectionTitle
          text="Let's have a chat (no obligations)"
          color={primaryColor}
          level="h1"
          size="3rem"
        />
        <Subtitle text="Let us know what time suits you. We'll have a cup of coffee and understand your accounting needs." />
        <CalendlyContainer>
          <iframe
            src="https://calendly.com/mishalshah/60min"
            width="100%"
            height="100%"
            frameborder="0"
          ></iframe>
        </CalendlyContainer>
      </StyledDiv>
    </Layout>
  )
}

export default BookPage
